
/**
 * @name: activity
 * @author: Gzm
 * @date: 2023-07-19 15:18
 * @description：商城装饰-活动专区管理
 * @update: 2023-07-19 15:18
 */
import {Component, Vue} from "vue-property-decorator";
import config from "@/config";
import {Message} from "element-ui";
import {ICrudOption} from "@/types/m-ui-crud";
import {
  activityRegionCreateApi,
  activityRegionQueryApi, activityRegionSetUpStatusApi,
} from "@/apis/decorate";
import {IPage} from "@/apis/page";
import {IBannerCreate} from "@/apis/decorate/types";


@Component({})
export default class BannerPage extends Vue {

  queryParam: IPage = {
    page: 1,
    limit: 10
  }
  tableDataDialog = [{
    activityName: '满减专区',
    pic: '',
    address: '上海市普陀区金沙江路 1518 弄'
  }, {
    activityName: '特价专区',
    pic: '',
    address: '上海市普陀区金沙江路 1517 弄'
  }]

  tableData: any[] = []

  // 新增修改表单
  modelForm: IBannerCreate = {}

  activityDialog = false

  /***
   * crudOption
   * 页面配置对象
   */
  crudOption: ICrudOption = {
    searchBox: false,
    addTitle: '编辑活动专区',
    editBtn: true,
    delBtn: true,
    menu: false,
    column: [
      {
        label: "序号",
        type: "index",
        align: "center"
      },
      {
        label: "活动名称",
        prop: "activityName",
        align: 'center',
        width: 200,
        addHide: true,
        editHide: true,
      },
      {
        label: "活动背景图片",
        prop: "pic",
        type: 'image',
        align: 'center',
        search: false,
        addSlot: true,
        editSlot: true,
        imgPrefix: config.downloadUrl,
        span: 24,
      },
      {
        label: "状态",
        prop: "status",
        align: 'center',
        slot: true,
        addHide: true,
        editHide: true,
        value: 2,
        type: "switch",
        dicData: [{label: '不展示', value: 2}, {label: '展示', value: 1}],
      },
      {
        label: "活动标题",
        prop: "title",
        addHide: true,
        editHide: true,
        align: 'center',
      },
      {
        label: "活动子标题",
        prop: "subTitle",
        addHide: true,
        editHide: true,
        align: 'center',
      },
      {
        label: "最近一次经办人",
        prop: "realName",
        addHide: true,
        editHide: true,
        align: "center"
      },
      {
        label: "更新时间",
        prop: "editTime",
        addHide: true,
        editHide: true,
        align: 'center',
      },
    ]
  }

  /**
   * 获取数据
   */
  getList() {
    activityRegionQueryApi(this.queryParam).then(e => {
      this.tableData = e;
    })
  }

  /**
   * 修改状态提交
   */
  handleStatus(row: any, val: number) {
    activityRegionSetUpStatusApi(row.id).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList()
      }
    })
  }

  /**
   * 上传前检验
   * @param file
   */
  beforeUpload(file: File) {
    if (file.type != "image/png" && file.type != "image/jpg" && file.type != "image/jpeg") {
      Message.error("请选择正确格式")
      return false
    }
  }

  openAdd() {
    this.activityDialog = true
    activityRegionQueryApi(this.queryParam).then(e => {
      this.tableDataDialog = e;
    })
  }

  handleAffirm() {
    console.log(this.tableDataDialog)
    activityRegionCreateApi(this.tableDataDialog).then(e => {
      if (e) {
        this.$message.success('编辑成功！')
        this.activityDialog = false
        this.getList()
      }
    })
  }

  created() {
    this.getList();
  }
}
